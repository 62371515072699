<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12">
        <h3>แลกสินค้า</h3>
        <hr class="mt-3">
      </v-col>
      <v-col
        v-for="(item,index) in points"
        md="3"
        sm="4"
        cols="6">
        <v-card>
          <v-card-text>
            <v-img
              width="100%"
              height="220"
              :src="item.image_path ? item.image_path : ''"
              :alt="item.image_path ? item.image_path : ''"
            ></v-img>
          </v-card-text>
          <v-card-text>
            <h4>{{ item.productPoint_name }} </h4>
            <p>จำนวนคงเหลือ : {{ item.amount }}</p>
            <p>แต้มคะแนน : {{ item.score }} คะแนน</p>
            <v-btn
              style="width: 100%"
              @click="showPointDetail(item)"
              class="primary"
            >แลกสินค้า
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12">
        <h3>ข่าวสารและโปรโมชั่น</h3>
        <hr class="mt-3">
      </v-col>
      <v-col
        v-for="(item,index) in news"
        md="3"
        sm="4"
        cols="6">
        <v-card>
          <v-card-text
            @click="showDetail(item)"
            style="cursor: pointer">
            <v-img
              width="100%"
              height="220"
              :src="item.image_path ? item.image_path : ''"
              :alt="item.image_path ? item.image_path : ''"
            ></v-img>
          </v-card-text>
          <v-card-text>
            <p class="text-center">{{ item.updated_at }} </p>
            <h4 class="text-center">{{ item.title }}</h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card-text>
      <div class="mt-4 warpper__contentBottom">
        <div>
          <p class="text-center">แสดง 1 ถึง {{ page }} จาก {{ length_page }} ผลลัพธ์</p>
          <!-- <a @click="clickFirstPage">หน้าแรก</a> -->
          <v-pagination
            @input="changePage"
            v-model="page" :length="length_page" total-visible="7"></v-pagination>
          <!-- <a @click="clickLastPage">หน้าสุดท้าย</a> -->
        </div>
      </div>
    </v-card-text>


    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ editedItem.title }}</span>
        </v-card-title>
        <v-card-text>
          <v-img
            width="100%"
            :src="editedItem.image_path"
            :alt="editedItem.image_path"
          ></v-img>
          <h4 class="mt-5">{{ editedItem.content }}</h4>
          <p>{{ editedItem.updated_at }}</p>
        </v-card-text>

        <v-card-text>
          <v-container>
            <v-row>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="close">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPoint" max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ editedItem.title }}</span>
        </v-card-title>
        <v-card-text>
          <v-img
            width="100%"
            :src="editedPointItem.image_path ? editedPointItem.image_path : ''"
            :alt="editedPointItem.image_path ? editedPointItem.image_path : ''"
          ></v-img>
          <h4 class="mt-5">{{ editedPointItem.productPoint_name }} </h4>
          <p>จำนวนคงเหลือ : {{ editedPointItem.amount }}</p>
          <p>แต้มคะแนน :{{ editedPointItem.score }} คะแนน</p>
        </v-card-text>

        <v-card-text>
          <v-container>
            <v-row>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error"
                 @click="closePoint"
                 text>
            ยกเลิก
          </v-btn>
          <v-btn
            @click="confirmExchange"
            class="success-badge" text>
            ยืนยันแลกสินค้า
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '@/axios.service'
import instance_news from '@/services/news'
import instance_point from '@/services/point'
import instance_user from '@/services/userManage'
import Swal from "sweetalert2";
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength} from '@vuelidate/validators'

export default {
  components: {},
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    page: 1,
    per_page: 12,
    length_page: 1,
    page_point: 1,
    per_point_page: 12,
    length_point_page: 1,
    news: [],
    points: [],
    rows: 100,
    perPage: 1,
    currentPage: 5,
    dialog: false,
    dialogPoint: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index'
      },
      {text: 'รูป/ภาพ', value: 'image'},
      {text: 'หัวข้อข่าวสาร', value: 'title'},
      {text: 'เผยแพร่', value: 'labelStatus'},
      {text: 'วันที่อัพเดท', value: 'updated_at'},
      {text: 'สถานะ (เปิด/ปิด)', value: 'status'},
      {text: 'สร้างโดย', value: 'employee.employee_name'},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedPointIndex: -1,
    editedItem: {
      file: null,
      status: '',
      title: '',
      employee: '',
      updated_at: '',
    },
    defaultItem: {
      file: null,
      status: '',
      title: '',
      employee: '',
      updated_at: '',
    },
    editedPointItem: {
      amount: '',
      detail: '',
      image_path: '',
      productPoint_name: '',
      score: '',
      stock: '',
    },
    defaultPointItem: {
      amount: '',
      detail: '',
      image_path: '',
      productPoint_name: '',
      score: '',
      stock: '',
    },
  }),
  validations() {
    return {
      editedItem: {
        title: {required},
        content: {required},
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ข่าวสาร' : 'แก้ไข ข่าวสาร'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {

  },
  mounted() {
    this.getPoint()
    this.getNews()
  },

  methods: {
    changePage() {
      this.getNews()
    },
    showDetail(item) {
      this.editedIndex = this.news.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },
    showPointDetail(item) {
      this.editedPointIndex = this.points.indexOf(item)
      this.editedPointItem = {...item}
      this.dialogPoint = true
    },
    confirmExchange() {
      this.$store.state.isLoading = true
      var data = {
        product_point_id: this.editedPointItem.productPoint_id,
        score : this.editedPointItem.score
      }
      instance_user.exchange(data).then(res => {
        if (res.data.success){

          Swal.fire({
            text: 'แลกสินค้าสำเสร็จ',
            timer: 3000,
            icon: 'success',
            showConfirmButton: false,
            button: false
          })
          this.getPoint()
          this.closePoint();

        }else{
          Swal.fire({
            text: res.data.message,
            timer: 3000,
            icon: 'error',
            showConfirmButton: false,
            button: false
          })
          this.$store.state.isLoading = false
          return false;
        }

      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false
      })
    },
    getNews() {
      this.$store.state.isLoading = true
      var data = {
        page: this.page
      }
      instance_news.getUserNews(data).then(res => {
        this.news = res.data.data.data
        this.length_page = (res.data.data.last_page);
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    getPoint() {
      this.$store.state.isLoading = true
      var data = {
        page: this.page
      }
      instance_point.getUserPoint(data).then(res => {
        this.points = res.data.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.news.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },
    close() {
      this.v$.$reset();
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    closePoint() {
      this.v$.$reset();
      this.dialogPoint = false
      this.$nextTick(() => {
        this.editedPointItem = {...this.defaultItem}
        this.editedPointIndex = -1
      })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
